import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { get, reduce } from 'lodash';
import Typography from '../Typography';
import withTheme from '../Theme/withTheme';
import { reduceStyleObjectToCss } from '../utils/js-style-to-css';

const Button = ({ children, compact, fullWidth, rounded, theme, uiTheme, ...otherProps }) => (
  <button {...otherProps}>
    <Typography preset="button" color="inherit">
      {children}
    </Typography>
  </button>
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  compact: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  rounded: PropTypes.bool,
  style: PropTypes.object,
  theme: PropTypes.string,
  type: PropTypes.string,
  uiTheme: PropTypes.object,
};

Button.defaultProps = {
  compact: false,
  disabled: false,
  fullWidth: false,
  rounded: false,
  style: {},
  theme: null,
  type: 'submit',
  uiTheme: null,
};

function getTheme(theme, name) {
  return get(theme, `button.themes[${name}]`, {});
}

function getPreset(theme, name) {
  return get(theme, `button.presets[${name}]`, {});
}

const StyledButton = styled(Button)`
  ${props => {
    const { compact, disabled, fullWidth, rounded, theme, uiTheme } = props;

    let style = { ...getPreset(uiTheme, 'base') };
    if (theme) style = { ...style, ...getTheme(uiTheme, theme) };
    if (compact) style = { ...style, ...getPreset(uiTheme, 'compact') };
    if (fullWidth) style = { ...style, ...getPreset(uiTheme, 'fullWidth') };
    if (disabled) style = { ...style, ...getPreset(uiTheme, 'disabled') };
    if (rounded) style = { ...style, ...getPreset(uiTheme, 'rounded') };

    return reduce(style, reduceStyleObjectToCss, '');
  }}
`;

Button.displayName = 'Button';
export default withTheme(StyledButton);
