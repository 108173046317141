import { isObject, reduce } from 'lodash';

// converts Javascript names for style props to the css names e.g. lineHeight to line-height
const convertStyleKey = string => {
  return string.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();
};

// TODO make more comprehensive list;
const nonPixelProps = ['opacity', 'lineHeight', 'fontWeight'];

// The aim here is to convert js values to CSS versions. E.g. change 24 to 24px for widths
export const convertStyleValue = (value, key) => {
  if (Number.isInteger(value) && !nonPixelProps.includes(key)) {
    return `${value}px`;
  }

  return value;
};

// Take a javascript style object key and value and convert to a CSS string.
// Used in reduce functions with the `css` argument being the carried string.
export const reduceStyleObjectToCss = (css, value, key) => {
  let propCss = '';
  if (isObject(value)) {
    propCss = `${key} {
     ${reduce(value, reduceStyleObjectToCss, '')}
    }`;
  } else {
    propCss = `${convertStyleKey(key)}: ${convertStyleValue(value, key)};`;
  }

  return `${css}
  ${propCss}`;
};
