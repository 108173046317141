import React from "react"
import { Link } from "gatsby"
import Cushion from "../ui/Cushion"
import Typography from "../ui/Typography"

const ContactLinks = ({ mobile, landline, email }) => {
  return (
    <div>
      <Typography component="div" preset="heading" color="white">
        Contact
      </Typography>
      <Cushion component="div" top="small">
        <Link to="/book/">
          <Cushion bottom="xsmall">
            <Typography
              component="div"
              preset="nav"
              color="white"
              opacity={0.7}
            >
              Get a quote online
            </Typography>
          </Cushion>
        </Link>
        <Link to="/contact/">
          <Cushion bottom="xsmall">
            <Typography
              component="div"
              preset="nav"
              color="white"
              opacity={0.7}
            >
              Contact us
            </Typography>
          </Cushion>
        </Link>
        <Cushion bottom="xsmall">
          <Typography component="div" preset="nav" color="white" opacity={0.7}>
            Telephone: <a href={`tel:+44${landline}`}>{landline}</a> or{" "}
            <a href={`tel:+44${mobile}`}>{mobile}</a>
          </Typography>
        </Cushion>
        <Cushion bottom="xsmall">
          <Typography component="div" preset="nav" color="white" opacity={0.7}>
            Email: <a href={`mailto:${email}`}>{email}</a>
          </Typography>
        </Cushion>
      </Cushion>
    </div>
  )
}

export default ContactLinks
