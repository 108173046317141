export const openSans = "'Open Sans', Arial, Helvetica, sans-serif"

export default {
  presets: {
    body: {
      family: openSans,
      weight: "normal",
      size: 16,
      lineHeight: "22px",
    },
    nav: {
      family: openSans,
      weight: 400,
      size: 16,
      lineHeight: "22px",
      textDecoration: "none",
      fontStyle: "normal",
    },
    button: {
      family: openSans,
      weight: 600,
      size: 16,
      lineHeight: "22px",
      fontStyle: "italic",
    },
    tag: {
      family: openSans,
      weight: 800,
      fontStyle: "italic",
      size: 12,
      lineHeight: "17px",
    },
    error: {
      family: openSans,
      weight: 800,
      fontStyle: "italic",
      size: 12,
      lineHeight: "17px",
      color: "red",
    },
    heading: {
      family: openSans,
      weight: 600,
      fontStyle: "italic",
      size: 24,
      lineHeight: "36px",
    },
    subheading: {
      family: openSans,
      weight: 600,
      fontStyle: "italic",
      size: 20,
      lineHeight: "30px",
    },
    hero: {
      family: openSans,
      weight: 800,
      fontStyle: "italic",
      size: 32,
      lineHeight: "48px",
      responsive: [
        { minWidth: "tab", props: { size: 60, lineHeight: "82px" } },
      ],
    },
    description: {
      family: openSans,
      weight: 400,
      size: 12,
      lineHeight: "14px",
    },
    label: {
      family: openSans,
      weight: 600,
      fontStyle: "italic",
      size: 16,
      lineHeight: "24px",
    },
    tab: {
      family: openSans,
      weight: "normal",
      fontStyle: "italic",
      size: 16,
      lineHeight: "22px",
    },
    "tab--active": {
      family: openSans,
      weight: "normal",
      size: 16,
      lineHeight: "22px",
    },
  },
}
