import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Cushion from "../ui/Cushion"
import Rectangle from "../ui/Rectangle"
import Typography from "../ui/Typography"

const Tab = ({ content, isActive, link }) => {
  if (isActive) {
    return (
      <Link to={link}>
        <Cushion horizontal="medium" vertical="small">
          <Rectangle radius="5px 5px 0 0" fill="white">
            <Typography component="div" preset="tab--active" color="darkGrey">
              {content}
            </Typography>
          </Rectangle>
        </Cushion>
      </Link>
    )
  }

  return (
    <Link to={link}>
      <Cushion horizontal="medium" vertical="small">
        <Typography component="div" preset="tab" color="medGrey">
          {content}
        </Typography>
      </Cushion>
    </Link>
  )
}

Tab.propTypes = {
  content: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
}
export default Tab
