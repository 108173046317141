import React from "react"
import PropTypes from "prop-types"
import "./hamburgers.css"

const HamburgerIcon = ({ isActive, onClick, style: customStyle }) => {
  let classString = "hamburger hamburger--spin-r"
  if (isActive) {
    classString = classString + " is-active"
  }

  return (
    <button className={classString} onClick={onClick} type="button" style={customStyle}>
      <span className="hamburger-box">
        <span className="hamburger-inner"></span>
      </span>
    </button>
  )
}

HamburgerIcon.defaultProps = {
  style: {},
};
HamburgerIcon.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  style: PropTypes.shape({}),
}
export default HamburgerIcon
