import React from "react"
import logo from "../../images/arpf-logo.png"
import Cushion from "../ui/Cushion"
import MaxWidth from "../ui/MaxWidth"
import Typography from "../ui/Typography"

const Logo = () => {
  return (
    <Cushion bottom="xxsmall">
      <MaxWidth component="div" maxWidth={177} style={{ position: "relative" }}>
        <img
          alt="A-rated Powerflush logo"
          src={logo}
          style={{ width: "100%" }}
        />
        <Typography
          preset="tag"
          color="medGrey"
          style={{ position: "absolute", bottom: "0", left: 12 }}
        >
          #BudgeThatSludge
        </Typography>
      </MaxWidth>
    </Cushion>
  )
}

export default Logo
