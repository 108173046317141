import React from "react"
import PropTypes from "prop-types"
import Cushion from "../ui/Cushion"
import Flex from "../ui/Flex"
import Rectangle from "../ui/Rectangle"
import Tab from "./tab"

const DomesticCommercialTabBar = ({ currentPathname }) => {
  const isCommercial = currentPathname === "/commercial/"
  return (
    <Cushion top="small" horizontal="medium">
      <Rectangle component="div" fill="vLightGrey">
        <Flex alignItems="center">
          <Tab content="Domestic" link="/" isActive={!isCommercial} />
          <Tab
            content="Commercial"
            link="/commercial/"
            isActive={isCommercial}
          />
        </Flex>
      </Rectangle>
    </Cushion>
  )
}

DomesticCommercialTabBar.propTypes = {
  currentPathname: PropTypes.string.isRequired,
}
export default DomesticCommercialTabBar
