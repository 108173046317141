import { get } from 'lodash';

export function getBorder(theme, border) {
  return get(theme, `borders.${border}`, border);
}

export function getColor(theme, color) {
  return get(theme, `colors.${color}`, color);
}

export function getPreset(theme, presetType, preset) {
  return get(theme, `${presetType}.presets.${preset}`, {});
}

export function getRadius(theme, radius) {
  return get(theme, `radii.${radius}`, radius);
}

export function getShadow(theme, value) {
  return get(theme, `shadows.${value}`, value);
}

export function getSpacing(theme, value) {
  return get(theme, `spacing.${value}`, value);
}

export function getBreakpoint(theme, breakpoint) {
  return get(theme, `breakpoints.${breakpoint}`, breakpoint);
}

export function getShimmerOptions(theme, name) {
  return get(theme, `shimmer.${name}`, {});
}
