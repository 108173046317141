import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Button from "../ui/Button"
import Cushion from "../ui/Cushion"
import Flex from "../ui/Flex"
import Hide from "../ui/Hide"
import Show from "../ui/Show"
import DesktopMenu from "./DesktopMenu"
import Logo from "./Logo"
import MobileMenu from "./MobileMenu"

const navItems = [
  { label: "What is a Powerflush?", link: "/explain-powerflush/" },
  { label: "Why should I get a Powerflush", link: "/reasons-for-powerflush/" },
  { label: "Knowledge centre", link: "/knowledge-centre/" },
  { label: "About us", link: "/about/" },
  { label: "Contact us", link: "/contact/" },
]
const Navigation = ({ currentPathname, landline, email }) => {
  return (
    <nav>
      <Flex alignItems="center">
        <div style={{ flexGrow: 1 }}>
          <Link to="/">
            <Logo />
          </Link>
        </div>
        <Show component="div" minWidth={1148}>
          <DesktopMenu items={navItems} currentPathname={currentPathname} />
        </Show>
        <Show component="div" minWidth={479}>
          <Link to="/book/">
            <Button theme="red" compact>
              Get a quote
            </Button>
          </Link>
        </Show>
        <Hide component="div" minWidth={1148}>
          <Cushion left="medium" component="div">
            <MobileMenu
              items={navItems}
              currentPathname={currentPathname}
              landline={landline}
              email={email}
            />
          </Cushion>
        </Hide>
      </Flex>
    </nav>
  )
}

Navigation.propTypes = {
  currentPathname: PropTypes.string.isRequired,
}
export default Navigation
