import React from "react"
import PropTypes from "prop-types"
import { get } from "lodash"
import Cushion from "../ui/Cushion"
import Rectangle from "../ui/Rectangle"
import Typography from "../ui/Typography"
import withTheme from "../ui/Theme/withTheme"
import Svg from "./Svg"

const ConvexTop = ({
  background: backgroundKey,
  children,
  fill: fillKey,
  uiTheme,
}) => {
  const background = get(uiTheme, `colors.${backgroundKey}`, backgroundKey)
  const fill = get(uiTheme, `colors.${fillKey}`, fillKey)

  return (
    <Rectangle fill={background}>
      <Cushion style={{ position: "relative" }}>
        <Typography component="div" lineHeight="0px">
          <Svg fill={fill} />
        </Typography>
        {children}
      </Cushion>
    </Rectangle>
  )
}

ConvexTop.defaultProps = {
  background: "transparent",
  children: null,
  fill: "white",
  uiTheme: {},
}
ConvexTop.propTypes = {
  background: PropTypes.string,
  children: PropTypes.node,
  fill: PropTypes.string,
  uiTheme: PropTypes.shape({}),
}
export default withTheme(ConvexTop)
