import { getBorder, getColor, getRadius } from '../Theme/selectors';
import { convertStyleValue } from '../utils/js-style-to-css';

const rectanglePropsToCss = ({
  uiTheme,
  fill,
  radius,
  borderTop,
  borderRight,
  borderBottom,
  borderLeft,
  border,
  inline,
}) => {
  return `
    ${fill ? `background-color: ${getColor(uiTheme, fill)};` : ''}
    ${
      radius
        ? `border-radius: ${convertStyleValue(getRadius(uiTheme, radius), 'borderRadius')};`
        : ''
    }
    ${inline ? 'display: inline-block;' : ''};
    ${
      borderTop || border
        ? `border-top: ${getBorder(uiTheme, borderTop) || getBorder(uiTheme, border)};`
        : ''
    }
    ${
      borderRight || border
        ? `border-right: ${getBorder(uiTheme, borderRight) || getBorder(uiTheme, border)};`
        : ''
    }
    ${
      borderBottom || border
        ? `border-bottom: ${getBorder(uiTheme, borderBottom) || getBorder(uiTheme, border)};`
        : ''
    }
    ${
      borderLeft || border
        ? `border-left: ${getBorder(uiTheme, borderLeft) || getBorder(uiTheme, border)};`
        : ''
    }
  `;
};

export default rectanglePropsToCss;
