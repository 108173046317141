import React from 'react';
import { merge } from 'lodash';

export default function renderByCloning(
  component,
  children,
  childProps,
  fallbackComponent = 'div',
) {
  const { className = '', style, ...otherProps } = childProps;
  if (component) {
    const Component = component;
    return (
      <Component {...otherProps} className={className} style={style}>
        {children}
      </Component>
    );
  }

  const childArray = React.Children.toArray(children);
  if (childArray.length === 1 && !!childArray[0].type) {
    const child = childArray[0];
    const { className: childClassName = '', style: childStyle, ...otherChildProps } = child.props;
    const combinedStyle = childStyle && style ? merge({}, style, childStyle) : childStyle || style;
    return React.cloneElement(child, {
      className: `${className} ${childClassName}`,
      style: combinedStyle,
      ...otherProps,
      ...otherChildProps,
    });
  }

  const FallBackComponent = fallbackComponent;
  return (
    <FallBackComponent {...otherProps} className={className} style={style}>
      {children}
    </FallBackComponent>
  );
}
