import { convertStyleValue } from "../utils/js-style-to-css"

function setMargin(center, right, left) {
  if (left) {
    return '0 auto 0 0'
  }

  if (right) {
    return '0 0 0 auto'
  }

  if (center) {
    return '0 auto'
  }

  return '0'
}

const maxWidthPropsToCss = ({ maxWidth, center, right, left }) => {
  return `
    max-width: ${convertStyleValue(maxWidth)};
    margin: ${setMargin(center, right, left)};
  `
}

export default maxWidthPropsToCss
