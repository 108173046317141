import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import emailImage from "../../images/email.svg"
import phoneImage from "../../images/phone.svg"
import Button from "../ui/Button"
import Cushion from "../ui/Cushion"
import Rectangle from "../ui/Rectangle"
import Typography from "../ui/Typography"
import NoScroll from "../ui/LockPageScroll/noScroll"

import HamburgerIcon from "./HamburgerIcon"
import Flex from "../ui/Flex"

const MobileMenu = ({ currentPathname, items, landline, email }) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (isOpen) {
      NoScroll.on()
    } else {
      NoScroll.off()
    }
  }, [isOpen])
  return (
    <div>
      <Flex alignItems="center">
        <Cushion component="div" right="small">
          <a href={`tel:+44${landline}`}>
            <img
              alt="Call A-rated PowerFlush"
              src={phoneImage}
              style={{ width: "100%" }}
            />
          </a>
        </Cushion>
        <Cushion component="div" right="small">
          <a href={`mailto:${email}`}>
            <img
              alt="Email A-rated PowerFlush"
              src={emailImage}
              style={{ width: "100%" }}
            />
          </a>
        </Cushion>
        <HamburgerIcon
          isActive={isOpen}
          onClick={() => {
            setIsOpen(!isOpen)
          }}
          style={{ position: "relative", zIndex: 10 }}
        />
      </Flex>
      <Cushion all="xlarge" top="xxxxxlarge">
        <Rectangle
          fill="white"
          style={{
            position: "fixed",
            width: "100%",
            height: "100%",
            top: isOpen ? 0 : "-100%",
            left: 0,
            boxSizing: "border-box",
            zIndex: 9,
            transition: "top 300ms ease-out",
            textAlign: "center",
            overflowY: "scroll",
          }}
        >
          {items.map(({ label, link }) => {
            const isCurrent = currentPathname === link
            return (
              <Link to={link} key={label} onClick={() => setIsOpen(false)}>
                <Cushion component="div" bottom="xlarge">
                  <Button
                    theme={isCurrent ? "textBlue" : "textBlueHover"}
                    style={{
                      transition: "color 300ms ease-out",
                    }}
                    fullWidth
                  >
                    <Typography preset="nav">{label}</Typography>
                  </Button>
                </Cushion>
              </Link>
            )
          })}
          <Link to="/book/">
            <Button theme="red" compact>
              Book online
            </Button>
          </Link>
        </Rectangle>
      </Cushion>
    </div>
  )
}

MobileMenu.propTypes = {
  currentPathname: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}
export default MobileMenu
