import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { styledComponentsResponsiveStyles } from '../utils/responsive';
import { getPreset } from '../Theme/selectors';
import withTheme from '../Theme/withTheme';
import typographyPropsToCss from './props-to-css';

const Typography = ({
  align,
  color,
  family,
  fontStyle,
  letterSpacing,
  lineHeight,
  opacity,
  preset,
  size,
  textDecoration,
  transform,
  weight,
  component: Component,
  responsive,
  uiTheme,
  ...otherProps
}) => <Component {...otherProps} />;

Typography.propTypes = {
  align: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  family: PropTypes.string,
  fontStyle: PropTypes.string,
  letterSpacing: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lineHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  opacity: PropTypes.number,
  preset: PropTypes.string,
  responsive: PropTypes.array,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textDecoration: PropTypes.string,
  transform: PropTypes.string,
  uiTheme: PropTypes.object,
  weight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Typography.defaultProps = {
  align: null,
  color: null,
  className: null,
  component: 'span',
  family: null,
  fontStyle: null,
  letterSpacing: null,
  lineHeight: null,
  opacity: null,
  preset: null,
  responsive: [],
  size: null,
  textDecoration: null,
  transform: null,
  uiTheme: {},
  weight: null,
};

const StyledTypography = styled(Typography)`
  ${props => {
    const { preset: presetName, uiTheme } = props;
    const preset = getPreset(uiTheme, 'typography', presetName);
    return typographyPropsToCss({
      family: 'inherit',
      size: '1rem',
      transform: 'none',
      weight: 'normal',
      opacity: 1,
      letterSpacing: 'auto',
      lineHeight: 1.5,
      ...preset,
      ...props,
    });
  }}
  ${props => {
    const { preset, uiTheme } = props;
    const { responsive: presetResponsive } = getPreset(uiTheme, 'typography', preset);
    return styledComponentsResponsiveStyles(uiTheme, presetResponsive, breakpointProps =>
      typographyPropsToCss({
        uiTheme,
        ...breakpointProps,
      }),
    );
  }}
  ${props => {
    const { responsive, uiTheme } = props;
    return styledComponentsResponsiveStyles(uiTheme, responsive, breakpointProps => {
      const { preset: presetName } = breakpointProps;
      const preset = presetName ? getPreset(uiTheme, 'typography', presetName) : {};
      return typographyPropsToCss({
        uiTheme,
        ...preset,
        ...breakpointProps,
      });
    });
  }}
`;

export default withTheme(StyledTypography);
