import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Button from "../ui/Button"
import Cushion from "../ui/Cushion"
import Flex from "../ui/Flex"
import Typography from "../ui/Typography"

const DesktopMenu = ({ currentPathname, items }) => {
  return (
    <Flex alignItems="center">
      {items.map(({ label, link }) => {
        const isCurrent = currentPathname === link

        return (
          <Link to={link} key={label}>
            <Cushion right="medium" component="div">
              <Button
                theme={isCurrent ? "textBlue" : "textBlueHover"}
                style={{
                  transition: "color 300ms ease-out",
                }}
              >
                <Typography preset="nav">{label}</Typography>
              </Button>
            </Cushion>
          </Link>
        )
      })}
    </Flex>
  )
}

DesktopMenu.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}
export default DesktopMenu
