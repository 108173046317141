import styled from 'styled-components';
import PropTypes from 'prop-types';
import { styledComponentsResponsiveStyles } from '../utils/responsive';
import renderByCloning from '../utils/renderByCloning';
import withTheme from '../Theme/withTheme';
import rectanglePropsToCss from './props-to-css';

const defaultProps = {
  onActive: null,
  border: 'none',
  borderBottom: null,
  borderLeft: null,
  borderRight: null,
  borderTop: null,
  children: null,
  component: null,
  fill: 'transparent',
  onHover: null,
  inline: false,
  radius: 0,
  responsive: [],
  style: {},
  uiTheme: null,
};

const Rectangle = ({
  onActive,
  border,
  borderBottom,
  borderLeft,
  borderRight,
  borderTop,
  children,
  component,
  fill,
  onHover,
  inline,
  radius,
  responsive,
  uiTheme,
  ...otherProps
}) => {
  return renderByCloning(component, children, { ...otherProps });
};

Rectangle.propTypes = {
  onActive: PropTypes.object,
  border: PropTypes.string,
  borderBottom: PropTypes.string,
  borderLeft: PropTypes.string,
  borderRight: PropTypes.string,
  borderTop: PropTypes.string,
  children: PropTypes.any,
  component: PropTypes.string,
  fill: PropTypes.string,
  onHover: PropTypes.object,
  inline: PropTypes.bool,
  radius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  responsive: PropTypes.array,
  style: PropTypes.object,
  uiTheme: PropTypes.object,
};

Rectangle.defaultProps = defaultProps;

const StyledRectangle = styled(Rectangle)`
  ${props => rectanglePropsToCss(props)}
  ${props => {
    const { responsive, uiTheme } = props;
    return styledComponentsResponsiveStyles(uiTheme, responsive, breakpointProps =>
      rectanglePropsToCss({
        uiTheme,
        ...breakpointProps,
      }),
    );
  }}

  ${props =>
    props.onActive &&
    `:active {
      ${rectanglePropsToCss(props.onActive)}
    };`}

  ${props =>
    props.onHover &&
    `cursor: pointer;
    transition: all 125ms ease-out;
    :hover {
      ${rectanglePropsToCss(props.onHover)}
    };`}
`;
StyledRectangle.defaultProps = defaultProps;

export default withTheme(StyledRectangle);
