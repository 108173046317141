import React from "react"
import PropTypes from "prop-types"
import ConvexTop from "../ConvexTop"
import Cushion from "../ui/Cushion"
import Flex from "../ui/Flex"
import Rectangle from "../ui/Rectangle"
import Typography from "../ui/Typography"
import ContactLinks from "./ContactLinks"
import InformationLinks from "./InformationLinks"

const Footer = ({ footerBackgroundColor, email, landline, mobile }) => {
  return (
    <Rectangle component="div" fill={footerBackgroundColor}>
      <ConvexTop fill="black">
        <Cushion all="xlarge">
          <Rectangle fill="black">
            <Flex
              flexDirection="column"
              responsive={[
                { minWidth: "tab", props: { flexDirection: "row" } },
              ]}
            >
              <Cushion
                component="div"
                bottom="medium"
                responsive={[
                  {
                    minWidth: "tab",
                    props: { bottom: "0px", right: "medium" },
                  },
                ]}
              >
                <InformationLinks />
              </Cushion>
              <ContactLinks email={email} landline={landline} mobile={mobile} />
            </Flex>
            <Cushion top="xlarge">
              <Typography
                component="div"
                preset="body"
                color="white"
                opacity={0.4}
              >
                Website designed and developed by{" "}
                <a href="https://www.linkedin.com/in/chris-cooper-a77b8511a/s">
                  Chris Cooper.
                </a>
              </Typography>
            </Cushion>
          </Rectangle>
        </Cushion>
      </ConvexTop>
    </Rectangle>
  )
}

Footer.propTypes = {
  footerBackgroundColor: PropTypes.string.isRequired,
}
export default Footer
