import React from "react"
import { Link } from "gatsby"
import Cushion from "../ui/Cushion"
import Typography from "../ui/Typography"

const InformationLinks = () => {
  return (
    <div>
      <Typography component="div" preset="heading" color="white">
        Information
      </Typography>
      <Cushion component="div" top="small">
        <Link to="/explain-powerflush/">
          <Cushion bottom="xsmall">
            <Typography
              component="div"
              preset="nav"
              color="white"
              opacity={0.7}
            >
              What is a Powerflush?
            </Typography>
          </Cushion>
        </Link>
        <Link to="/reasons-for-powerflush/">
          <Cushion bottom="xsmall">
            <Typography
              component="div"
              preset="nav"
              color="white"
              opacity={0.7}
            >
              Why should i get a Powerflush?
            </Typography>
          </Cushion>
        </Link>
        <Link to="/knowledge-centre/">
          <Cushion bottom="xsmall">
            <Typography
              component="div"
              preset="nav"
              color="white"
              opacity={0.7}
            >
              Knowledge centre
            </Typography>
          </Cushion>
        </Link>
        <Link to="/commercial/">
          <Cushion bottom="xsmall">
            <Typography
              component="div"
              preset="nav"
              color="white"
              opacity={0.7}
            >
              Commercial
            </Typography>
          </Cushion>
        </Link>
        <Link to="/about/">
          <Cushion bottom="xsmall">
            <Typography
              component="div"
              preset="nav"
              color="white"
              opacity={0.7}
            >
              About us
            </Typography>
          </Cushion>
        </Link>
      </Cushion>
    </div>
  )
}

export default InformationLinks
