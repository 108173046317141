import styled from 'styled-components';
import PropTypes from 'prop-types';
import renderByCloning from '../utils/renderByCloning';
import { getBreakpoint } from '../Theme/selectors';
import withTheme from '../Theme/withTheme';

const Hide = ({ children, component, maxWidth, minWidth, uiTheme, ...otherProps }) => {
  return renderByCloning(component, children, { ...otherProps });
};

Hide.propTypes = {
  children: PropTypes.any.isRequired,
  component: PropTypes.string,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  uiTheme: PropTypes.object,
};

Hide.defaultProps = {
  component: null,
  maxWidth: null,
  minWidth: null,
  uiTheme: null,
};

const StyledHide = styled(Hide)`
  ${props => {
    const { maxWidth: maxWidthKey, minWidth: minWidthKey, uiTheme } = props;

    const minWidth = getBreakpoint(uiTheme, minWidthKey);
    const maxWidth = getBreakpoint(uiTheme, maxWidthKey);

    if (minWidth && maxWidth) {
      return `@media screen and (min-width: ${minWidth}px) and (max-width: ${maxWidth}px) {
        display: none;
      }`;
    }

    if (minWidth) {
      return `@media screen and (min-width: ${minWidth}px) {
        display: none;
      }`;
    }

    if (maxWidth) {
      return `@media screen and (max-width: ${maxWidth}px) {
        display: none;
      }`;
    }

    return '';
  }}
`;

export default withTheme(StyledHide);
