import React from 'react';
import ThemeContext from '../Context';

const withTheme = WrappedComponent => {
  function HOC(props) {
    return (
      <ThemeContext.Consumer>
        {value => <WrappedComponent {...value} {...props} />}
      </ThemeContext.Consumer>
    );
  }
  HOC.displayName = WrappedComponent.displayName || 'withTheme';
  return HOC;
};

export default withTheme;
