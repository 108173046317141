import styled from 'styled-components';
import PropTypes from 'prop-types';
import { styledComponentsResponsiveStyles } from '../utils/responsive';
import renderByCloning from '../utils/renderByCloning';
import withTheme from '../Theme/withTheme';
import maxWidthPropsToCss from './props-to-css';

const MaxWidth = ({ center, children, component, maxWidth, responsive, uiTheme, ...otherProps }) =>
  renderByCloning(component, children, { ...otherProps });

MaxWidth.propTypes = {
  center: PropTypes.bool,
  right: PropTypes.bool,
  left: PropTypes.bool,
  children: PropTypes.node,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  responsive: PropTypes.arrayOf(PropTypes.shape({})),
};
MaxWidth.defaultProps = {
  center: false,
  right: false,
  left: false,
  children: null,
  component: null,
  maxWidth: 1200,
  responsive: [],
  style: {},
};

const StyledMaxWidth = styled(MaxWidth)`
  ${props => maxWidthPropsToCss(props)}
  ${props => {
    const { responsive, uiTheme } = props;
    return styledComponentsResponsiveStyles(uiTheme, responsive, breakpointProps =>
      maxWidthPropsToCss({
        ...breakpointProps,
      }),
    )
  }}
`;
export default withTheme(StyledMaxWidth);
