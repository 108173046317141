import React from "react"
import PropTypes from "prop-types"

const Svg = ({ fill }) => {
  return (
    <svg width="100%" length="auto" viewBox="0 0 1440 97" version="1.1">
      <g
        id="Homepage"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          id="covex-top"
          fill={fill}
          d="M0,78 C287.333333,26 527.333333,0 720,0 C912.666667,0 1152.66667,26 1440,78 L1440,97 L0,97 L0,78 Z"
        ></path>
      </g>
    </svg>
  )
}

Svg.propTypes = {
  fill: PropTypes.string,
}
export default Svg
