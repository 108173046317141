export default function({
    brandRed,
    brandBlue,
    brandYellow,
    darkGrey,
    medGrey,
    black,
    white,
  }) {

  return {
    themes: {
      blue: {
        backgroundColor: brandBlue,
        color: white,
        ':hover': {
          backgroundColor: '#223D6D',
        }
      },
      grey: {
        backgroundColor: '#d4d4d4',
        color: black,
        ':hover': {
          backgroundColor: '#b9b9b9',
        }
      },
      red: {
        backgroundColor: brandRed,
        color: white,
        ':hover': {
          backgroundColor: '#A02928',
        }
      },
      yellow: {
        backgroundColor: brandYellow,
        color: darkGrey,
      },
      textBlue: {
        color: brandBlue,
        paddingTop: 0,
        paddingRight: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        borderRadius: 0,
        backgroundColor: 'transparent',
      },
      textBlueHover : {
        color: darkGrey,
        paddingTop: 0,
        paddingRight: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        borderRadius: 0,
        backgroundColor: 'transparent',
        ':hover': {
          color: brandBlue,
        }
      }
    },

    presets: {
      base: {
        display: 'inline-block',
        position: 'relative',
        marginBottom: 0,
        marginLeft: 0,
        marginRight: 0,
        marginTop: 0,
        paddingBottom: 16,
        paddingLeft: 48,
        paddingRight: 48,
        paddingTop: 16,
        borderRadius: 27,
        fill: 'currentColor',
        transition: 'background-color 0.5s ease',
        outline: 0,
        cursor: 'pointer',
        border: 'none',
        whiteSpace: 'nowrap'
      },
      disabled: {
        opacity: 0.2,
        cursor: 'not-allowed',
      },
      fullWidth: {
        width: '100%',
      },
      compact: {
        paddingBottom: 8,
        paddingLeft: 24,
        paddingRight: 24,
        paddingTop: 8,
      },
    },
  };
}
