import React from "react"
import PropTypes from "prop-types"
import Navigation from "../Navigation"
import Cushion from "../ui/Cushion"
import Rectangle from "../ui/Rectangle"
import DomesticCommercialTabBar from "../DomesticCommercialTabBar"

const Header = ({ currentPathname, landline, email }) => {
  return (
    <React.Fragment>
      <DomesticCommercialTabBar currentPathname={currentPathname} />
      <Cushion
        all="small"
        responsive={[{ minWidth: "palm", props: { all: "medium" } }]}
      >
        <Rectangle component="header" fill="white">
          <Navigation
            currentPathname={currentPathname}
            landline={landline}
            email={email}
          />
        </Rectangle>
      </Cushion>
    </React.Fragment>
  )
}

Header.propTypes = {
  currentPathname: PropTypes.string.isRequired,
}
export default Header
