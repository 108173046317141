const flexPropsToCss = ({ flexDirection, alignItems, justifyContent, flexWrap }) => {
  return `
    display: flex;
    ${flexDirection ? `flex-direction: ${flexDirection};` : ''}
    ${alignItems ? `align-items: ${alignItems};` : ''}
    ${justifyContent ? `justify-content: ${justifyContent};` : ''}
    ${flexWrap ? `flex-wrap: ${flexWrap};` : ''}
`;
};

export default flexPropsToCss;
