import styled from 'styled-components';
import PropTypes from 'prop-types';
import { styledComponentsResponsiveStyles } from '../utils/responsive';
import withTheme from '../Theme/withTheme';
import flexPropsToCss from './props-to-css';

const Flex = styled.div`
  ${props => flexPropsToCss(props)}
  ${props => {
    const { responsive, uiTheme } = props;
    return styledComponentsResponsiveStyles(uiTheme, responsive, breakpointProps =>
      flexPropsToCss({
        uiTheme,
        ...breakpointProps,
      }),
    );
  }}
`;

Flex.propTypes = {
  flexDirection: PropTypes.string,
  alignItems: PropTypes.string,
  justifyContent: PropTypes.string,
  flexWrap: PropTypes.string,
  children: PropTypes.node.isRequired,
  responsive: PropTypes.array,
  uiTheme: PropTypes.object,
};

Flex.defaultProps = {
  flexDirection: 'row',
  alignItems: 'stretch',
  justifyContent: 'flex-start',
  flexWrap: 'nowrap',
  responsive: [],
  uiTheme: null,
};

export default withTheme(Flex);
