import breakpoints from "./breakpoints"
import borders from "./borders"
import button from "./button"
import colors from "./colors"
import radii from "./radii"
import shadows from "./shadows"
import spacing from "./spacing"
import typography from "./typography"

export default {
  breakpoints,
  borders: borders(colors),
  button: button(colors),
  colors,
  radii,
  shadows,
  spacing,
  typography,
}
