import { getColor } from '../Theme/selectors';
import { convertStyleValue } from '../utils/js-style-to-css';

const typographyPropsToCss = ({
  uiTheme,
  align,
  color,
  family,
  fontStyle,
  letterSpacing,
  lineHeight,
  opacity,
  size,
  textDecoration,
  transform,
  weight,
}) => {
  return `
    ${align ? `text-align: ${align};` : ''}
    ${color ? `fill: ${getColor(uiTheme, color)};` : ''}
    ${color ? `color: ${getColor(uiTheme, color)};` : ''}
    ${textDecoration ? `text-decoration: ${textDecoration};` : ''}
    ${family ? `font-family: ${family};` : ''}
    ${fontStyle ? `font-style: ${fontStyle};` : ''}
    ${size ? `font-size: ${convertStyleValue(size, 'fontSize')};` : ''}
    ${weight ? `font-weight: ${weight};` : ''}
    ${letterSpacing ? `letter-spacing: ${letterSpacing};` : ''}
    ${lineHeight ? `line-height: ${lineHeight};` : ''}
    ${opacity ? `opacity: ${opacity};` : ''}
    ${transform ? `text-transform: ${transform};` : ''}
`;
};

export default typographyPropsToCss;
