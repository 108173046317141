import { getBreakpoint } from '../Theme/selectors';

export default function setResponsiveStyles(uiTheme, responsive, styleFunction) {
  const style = {};

  responsive.map(breakpoint => {
    const {
      minWidth: minWidthKey,
      maxWidth: maxWidthKey,
      props: breakpointProps = {},
    } = breakpoint;
    const minBreakpoint = getBreakpoint(uiTheme, minWidthKey);
    const maxBreakpoint = getBreakpoint(uiTheme, maxWidthKey);
    const breakpointStyles = styleFunction(breakpointProps);

    if (minBreakpoint && maxBreakpoint) {
      style[
        `@media screen and (min-width: ${minBreakpoint}px) and (max-width: ${maxBreakpoint}px)`
      ] = breakpointStyles;
    } else if (minBreakpoint) {
      style[`@media screen and (min-width: ${minBreakpoint}px)`] = breakpointStyles;
    } else if (maxBreakpoint) {
      style[`@media screen and (max-width: ${maxBreakpoint}px)`] = breakpointStyles;
    }
    return breakpoint;
  });
  return style;
}

export function styledComponentsResponsiveStyles(uiTheme, responsive, styleFunction) {
  let style = '';
  if (!Array.isArray(responsive) || responsive.length === 0) {
    return style;
  }

  responsive.map(breakpoint => {
    const {
      minWidth: minWidthKey,
      maxWidth: maxWidthKey,
      props: breakpointProps = {},
    } = breakpoint;
    const minBreakpoint = getBreakpoint(uiTheme, minWidthKey);
    const maxBreakpoint = getBreakpoint(uiTheme, maxWidthKey);
    const breakpointStyles = styleFunction(breakpointProps);

    if (minBreakpoint && maxBreakpoint) {
      style += `@media screen and (min-width: ${minBreakpoint}px) and (max-width: ${maxBreakpoint}px) {
        ${breakpointStyles}
      }`;
    } else if (minBreakpoint) {
      style += `@media screen and (min-width: ${minBreakpoint}px) {
        ${breakpointStyles}
      }`;
    } else if (maxBreakpoint) {
      style += `@media screen and (max-width: ${maxBreakpoint}px) {
        ${breakpointStyles}
      }`;
    }
    return breakpoint;
  });
  return style;
}
