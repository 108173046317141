import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import theme from "../../lib/theme"
import ThemeProvider from "../ui/Theme/Provider"
import Rectangle from "../ui/Rectangle"
import Footer from "../Footer"
import Header from "../Header"
import "./normalize.css"
import "./global-styles.css"

const Frame = ({
  children,
  contactDetails: { mobile, landline, email },
  currentPathname,
  footerBackgroundColor,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-858634123" />
        <script>
          {`window.dataLayer = window.dataLayer || [];
          function gtag() {dataLayer.push(arguments);}; 
          gtag('js', new Date()); 
          gtag('config', 'AW-858634123'); `}
        </script>
        <script>
          {`gtag('config', 'AW-858634123/lXKjCKiduKMYEIvvtpkD', {
            'phone_conversion_number': '0192 473 1566'
          });`}
        </script>
        <script>
          {`gtag('config', 'AW-858634123/sCWJCP_QqqMYEIvvtpkD', {
            'phone_conversion_number': '0785 799 9180'
          });`}
        </script>
      </Helmet>
      <Rectangle
        fill="vLightGrey"
        component="div"
        style={{ minHeight: "100vh" }}
      >
        <Header
          currentPathname={currentPathname}
          landline={landline}
          email={email}
        />
        <main style={{ minHeight: "100vh" }}>{children}</main>
        <Footer
          footerBackgroundColor={footerBackgroundColor}
          mobile={mobile}
          landline={landline}
          email={email}
        />
      </Rectangle>
    </ThemeProvider>
  )
}

Frame.defaultProps = {
  children: null,
  footerBackgroundColor: "transparent",
}
Frame.propTypes = {
  children: PropTypes.node,
  currentPathname: PropTypes.string.isRequired,
  footerBackgroundColor: PropTypes.string,
}
export default Frame
