import React from 'react';
import PropTypes from 'prop-types';
import ThemeContext from '../Context';

export const containerId = 'react-ui-container';

const Provider = ({ theme, children }) => {
  return (
    <ThemeContext.Provider value={{ uiTheme: theme }}>
      <div id={containerId}>{children}</div>
    </ThemeContext.Provider>
  );
};

Provider.displayName = 'ThemeProvider';
Provider.propTypes = {
  theme: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};
export default Provider;
